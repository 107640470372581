<template>
  <div class="text-center">
    <h1 class="display-4">BREAKOUT GAME</h1>
    <div ref="gameContainer" class="game-container">
      <canvas ref="gameCanvas"></canvas>

      <!-- Score and High Score Display -->
      <div class="score-display">
        <div>Score: {{ score }}</div>
        <div>High Score: {{ highScore }}</div>
      </div>

      <!-- Lives Display as Hearts -->
      <div class="lives-display">
        <div v-for="n in lives" :key="n" class="heart">❤️</div>
      </div>

      <!-- Speed Display -->
      <div class="speed-display">
        Speed: x{{ speedMultiplier }}
      </div>

      <!-- Active Power-Ups Display -->
      <div class="power-up-display" v-if="activePowerUps.length">
        <div
          class="power-up-item"
          v-for="(powerUp, index) in activePowerUps"
          :key="index"
        >
          {{ powerUp.emoji }} {{ powerUp.timeLeft.toFixed(1) }}s
        </div>
      </div>

      <!-- Pause Button -->
      <button @click="togglePause" class="pause-button">{{ isPaused ? 'Resume' : 'Pause' }}</button>

      <!-- Game Over Overlay -->
      <div v-if="isGameOver" class="game-over-overlay">
        <div>{{ gameOverMessage }}</div>
        <div>Score: {{ score }}</div>
        <div>High Score: {{ highScore }}</div>
        <button @click="startOver" class="btn w-40">Start Over</button>
      </div>

      <!-- Start Game Overlay -->
      <div v-if="!gameStarted" class="game-start-overlay">
        <button @click="startGame" class="btn w-40">Start Game</button>
      </div>
    </div>
  </div>
</template>

<script>
import Matter from 'matter-js';

export default {
  name: 'MinigameView',
  data() {
    return {
      engine: null,
      render: null,
      world: null,
      runner: null,
      paddle: null,
      ball: null,
      bricks: [],
      gameWidth: 0,
      gameHeight: 0,
      isGameOver: false,
      gameOverMessage: '',
      score: 0,
      lives: 3,
      gameStarted: false,
      initialBallSpeed: 7, // Minimum speed
      currentBallSpeed: 7, // Current speed of the ball
      isPaused: false, // Pause state
      bricksDestroyed: 0, // Count of bricks destroyed since last row addition
      brickRows: 5, // Initial number of brick rows
      powerUps: [], // Array to store power-up bricks
      activePowerUps: [], // Active power-ups with timers
      pointMultiplier: 1, // Multiplier for points
      isPaddleExpanded: false, // Paddle expansion state
      originalPaddleWidth: 160, // Original paddle width
      highScore: localStorage.getItem('highScore') || 0
    };
  },
  computed: {
    speedMultiplier() {
      return (this.currentBallSpeed / this.initialBallSpeed).toFixed(2);
    },
  },
  mounted() {
    this.calculateGameContainerSize();
    window.addEventListener('resize', this.calculateGameContainerSize);
    // Do not start the game until the user clicks "Start Game"
  },
  methods: {
    startGame() {
      this.gameStarted = true;
      this.setupMatter();
    },
    setupMatter() {
      // Create an engine
      this.engine = Matter.Engine.create();
      this.world = this.engine.world;

      // No gravity in Breakout
      this.engine.world.gravity.y = 0;

      // Get the canvas element
      const canvas = this.$refs.gameCanvas;

      // Use the calculated dimensions
      const width = this.gameWidth;
      const height = this.gameHeight;

      // Set the canvas dimensions
      canvas.width = width;
      canvas.height = height;

      // Get CSS variables
      const backgroundColor = this.getCSSVariable('--cc_main') || '#2c3e50';

      // Create a renderer using the canvas
      this.render = Matter.Render.create({
        canvas: canvas,
        engine: this.engine,
        options: {
          width: width,
          height: height,
          wireframes: false,
          background: backgroundColor,
        },
      });

      // Create a runner and run the engine
      this.runner = Matter.Runner.create();
      Matter.Runner.run(this.runner, this.engine);

      // Run the renderer
      Matter.Render.run(this.render);

      // Add event listeners
      canvas.addEventListener('mousemove', this.movePaddle);
      canvas.addEventListener('touchmove', this.movePaddle);
      Matter.Events.on(this.engine, 'collisionStart', this.handleCollisions);

      // Setup walls and game objects
      this.setupWalls();
      this.setupGameObjects();

      // Start the game loop
      this.renderLoop();

      // Setup custom rendering events
      this.setupRenderEvents();
    },

    setupGameObjects() {
      const width = this.gameWidth;
      const height = this.gameHeight;

      // Paddle properties
      const paddleWidth = (width / 5)*1.5; // Paddle is 1/6 of the canvas width
      const paddleHeight = height / 40; // Paddle height is 1/40 of canvas height
      const paddleX = width / 2;
      const paddleY = height - paddleHeight * 3; // Adjust as needed

      // Store original paddle width
      this.originalPaddleWidth = paddleWidth;

      // Get CSS variables
      const paddleColor = this.getCSSVariable('--cc_second') || '#3498db';

      // Create paddle with rounded corners using chamfer
      this.paddle = Matter.Bodies.rectangle(paddleX, paddleY, paddleWidth, paddleHeight, {
        isStatic: true,
        chamfer: { radius: paddleHeight / 2 }, // Rounded corners
        render: { fillStyle: paddleColor },
      });
      Matter.World.add(this.world, this.paddle);

      // Ball properties
      const ballRadius = paddleHeight / 2; // Ball size relative to paddle height
      const ballX = width / 2;
      const ballY = paddleY - paddleHeight - ballRadius * 2; // Start above the paddle

      // Get CSS variables
      const ballColor = this.getCSSVariable('--cc_b') || '#e74c3c';

      // Calculate initial ball speed relative to canvas size
      const initialSpeed = height / 100; // Adjust divisor to change speed
      this.initialBallSpeed = initialSpeed;
      this.currentBallSpeed = initialSpeed;

      // Create ball
      this.ball = Matter.Bodies.circle(ballX, ballY, ballRadius, {
        restitution: 1,
        friction: 0,
        frictionAir: 0,
        slop: 0,
        inertia: Infinity, // Prevent rotation
        render: { fillStyle: ballColor },
      });
      Matter.World.add(this.world, this.ball);

      // Set initial velocity
      Matter.Body.setVelocity(this.ball, { x: initialSpeed * 0.7, y: -initialSpeed * 0.7 });

      // Create bricks
      this.createBrickRows(this.brickRows);
    },

    calculateGameContainerSize() {
      const aspectRatio = 9 / 16; // Desired aspect ratio (width / height)
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight * 0.9; // Use 90% of viewport height

      let width = viewportWidth;
      let height = width / aspectRatio;

      if (height > viewportHeight) {
        height = viewportHeight;
        width = height * aspectRatio;
      }

      // Now set the game container's dimensions
      this.gameWidth = width;
      this.gameHeight = height;
      this.$refs.gameContainer.style.width = `${width}px`;
      this.$refs.gameContainer.style.height = `${height}px`;
    },
    createBrickRows(numRows) {
      const width = this.gameWidth;
      const height = this.gameHeight;

      // Set brick height as a fraction of the game height
      const brickHeight = height / 20; // Adjust '20' to change brick size relative to canvas height

      // Set brick width equal to brick height to make square bricks
      const brickWidth = brickHeight;

      // Calculate how many bricks can fit in the width
      const brickCols = Math.floor(width / brickWidth);

      // Calculate horizontal margin to center the bricks
      const horizontalMargin = (width - (brickCols * brickWidth)) / 2;

      for (let row = 0; row < numRows; row++) {
        // Randomly select a power-up type
        const powerUpTypes = ['heart', 'wind', 'expand', 'double'];
        const powerUpType = powerUpTypes[Math.floor(Math.random() * powerUpTypes.length)];
        // Select a random column for the power-up in this row
        const powerUpCol = Math.floor(Math.random() * brickCols);

        for (let col = 0; col < brickCols; col++) {
          const brickX = horizontalMargin + col * brickWidth + brickWidth / 2;
          const brickY = row * brickHeight + brickHeight / 2 + brickHeight; // Adjust vertical position as needed

          const isPowerUp = col === powerUpCol;

          const brickOptions = {
            isStatic: true,
            render: {
              fillStyle: isPowerUp ? '#A9A9A9' : this.getRandomColor(),
              visible: !isPowerUp, // Hide default rendering for power-ups
            },
          };

          const brick = Matter.Bodies.rectangle(
            brickX,
            brickY,
            brickWidth - 2, // Subtract small value for spacing
            brickHeight - 2,
            brickOptions
          );

          if (isPowerUp) {
            brick.isPowerUp = true;
            brick.powerUpType = powerUpType;
            brick.animationOffset = Math.random() * 1000;
          }

          this.bricks.push(brick);
          Matter.World.add(this.world, brick);
        }
      }
    },

    movePaddle(event) {
      if (this.isGameOver || this.isPaused) return;

      const rect = this.$refs.gameCanvas.getBoundingClientRect();
      let clientX;

      if (event.type === 'mousemove') {
        clientX = event.clientX;
      } else if (event.type === 'touchmove') {
        event.preventDefault();
        clientX = event.touches[0].clientX;
      } else {
        return;
      }

      let mouseX = clientX - rect.left;

      // Ensure paddle stays within the game boundaries
      const halfPaddleWidth = (this.paddle.bounds.max.x - this.paddle.bounds.min.x) / 2;
      const minX = halfPaddleWidth;
      const maxX = this.gameWidth - halfPaddleWidth;

      if (mouseX < minX) mouseX = minX;
      if (mouseX > maxX) mouseX = maxX;

      // Update paddle position
      Matter.Body.setPosition(this.paddle, { x: mouseX, y: this.paddle.position.y });
    },

    handleCollisions(event) {
      const pairs = event.pairs;

      pairs.forEach((pair) => {
        const { bodyA, bodyB } = pair;

        // Ball and brick collision
        this.handleBrickCollision(bodyA, bodyB);

        // Ball and paddle collision
        if ((bodyA === this.ball && bodyB === this.paddle) || (bodyB === this.ball && bodyA === this.paddle)) {
          // Adjust ball velocity based on where it hits the paddle
          const deltaX = this.ball.position.x - this.paddle.position.x;
          const newVelocityX = deltaX * 0.1; // Adjust the multiplier as needed
          const newVelocityY = -Math.abs(this.ball.velocity.y);

          // Calculate new speed
          const newSpeed = Math.sqrt(newVelocityX ** 2 + newVelocityY ** 2);

          // Normalize the velocity to the current ball speed
          const normalizedVelocity = {
            x: (newVelocityX / newSpeed) * this.currentBallSpeed,
            y: (newVelocityY / newSpeed) * this.currentBallSpeed,
          };
          Matter.Body.setVelocity(this.ball, normalizedVelocity);
        }
      });
    },
    handleBrickCollision(bodyA, bodyB) {
      for (let index = 0; index < this.bricks.length; index++) {
        const brick = this.bricks[index];
        if ((bodyA === this.ball && bodyB === brick) || (bodyB === this.ball && bodyA === brick)) {
          // Remove brick from world and array
          Matter.World.remove(this.world, brick);
          this.bricks.splice(index, 1);

          // Increase score
          this.score += 10 * this.pointMultiplier;

          // Increase ball speed by 2% for every hit
          this.currentBallSpeed *= 1.02;

          // Check if brick is a power-up
          if (brick.isPowerUp) {
            this.applyPowerUp(brick.powerUpType);
          }

          // Increase destroyed bricks count
          this.bricksDestroyed += 1;

          // Check if it's time to add new row and move bricks down
          if (this.bricksDestroyed % 10 === 0) {
            this.moveBricksDown();
            this.addNewBrickRow();
          }

          break; // Break out of the loop since we found the colliding brick
        }
      }
    },
    handleAfterUpdate() {
      // Skip update if paused
      if (this.isPaused) return;

      const velocity = this.ball.velocity;
      const speed = Math.sqrt(velocity.x ** 2 + velocity.y ** 2);

      // Ensure the ball speed does not go below the initial speed
      const targetSpeed = Math.max(this.currentBallSpeed, this.initialBallSpeed);

      // Normalize the velocity to the target speed
      const normalizedVelocity = {
        x: (velocity.x / speed) * targetSpeed,
        y: (velocity.y / speed) * targetSpeed,
      };
      Matter.Body.setVelocity(this.ball, normalizedVelocity);

      // Update active power-up timers
      this.updatePowerUpTimers();

      // Check if ball is off-screen (below the canvas)
      if (this.ball.position.y > this.gameHeight + 50 && !this.isGameOver) {
        this.lives -= 1;

        if (this.lives > 0) {
          // Reset ball position
          this.resetBall();
        } else {
          this.displayGameOver('Game Over!');
        }
      }

      // Check if any bricks have moved below the paddle (lose condition)
      for (let brick of this.bricks) {
        if (brick.position.y >= this.paddle.position.y - 20) {
          this.displayGameOver('Game Over!');
          break;
        }
      }
    },
    applyPowerUp(type) {
      let emoji = '';
      if (type === 'heart') {
        this.lives += 1;
        // No timer needed
      } else if (type === 'wind') {
        this.currentBallSpeed = 1;
        // Ensure ball speed does not go below initialBallSpeed
        this.currentBallSpeed = Math.max(this.currentBallSpeed, this.initialBallSpeed);
        // No timer needed
      } else if (type === 'expand') {
        // Increase paddle width
        this.expandPaddle();
        emoji = '🏓';
      } else if (type === 'double') {
        // Double points
        this.pointMultiplier = 2;
        emoji = '✨';
      }

      // Add to active power-ups display if applicable
      if (emoji) {
        // Find if power-up is already active
        const existingPowerUp = this.activePowerUps.find((pu) => pu.type === type);
        if (existingPowerUp) {
          // Stack time
          existingPowerUp.timeLeft += 10;
        } else {
          this.activePowerUps.push({ type, emoji, timeLeft: 10 });
        }
      }
    },
    expandPaddle() {
      if (!this.isPaddleExpanded) {
        // Increase paddle width
        Matter.World.remove(this.world, this.paddle);
        const paddleWidth = this.originalPaddleWidth * 1.5;
        const paddleHeight = this.paddle.bounds.max.y - this.paddle.bounds.min.y;
        const paddleX = this.paddle.position.x;
        const paddleY = this.paddle.position.y;
        const paddleColor = this.getCSSVariable('--cc_second') || '#3498db';

        this.paddle = Matter.Bodies.rectangle(paddleX, paddleY, paddleWidth, paddleHeight, {
          isStatic: true,
          chamfer: { radius: paddleHeight / 2 },
          render: { fillStyle: paddleColor },
        });
        Matter.World.add(this.world, this.paddle);

        this.isPaddleExpanded = true;
      }
    },
    renderLoop() {
      Matter.Events.on(this.engine, 'afterUpdate', this.handleAfterUpdate);
    },
    updatePowerUpTimers() {
      this.activePowerUps.forEach((powerUp, index) => {
        powerUp.timeLeft = Math.max(0, powerUp.timeLeft - 1 / 60); // Assuming 60 FPS
        if (powerUp.timeLeft <= 0) {
          // Remove expired power-up
          this.activePowerUps.splice(index, 1);

          // Deactivate power-up effect
          if (powerUp.type === 'expand') {
            this.resetPaddleSize();
          } else if (powerUp.type === 'double') {
            this.pointMultiplier = 1;
          }
        }
      });
    },
    resetPaddleSize() {
      if (this.isPaddleExpanded) {
        // Reset paddle size
        Matter.World.remove(this.world, this.paddle);
        const paddleWidth = this.originalPaddleWidth;
        const paddleHeight = this.paddle.bounds.max.y - this.paddle.bounds.min.y;
        const paddleX = this.paddle.position.x;
        const paddleY = this.paddle.position.y;
        const paddleColor = this.getCSSVariable('--cc_second') || '#3498db';

        this.paddle = Matter.Bodies.rectangle(paddleX, paddleY, paddleWidth, paddleHeight, {
          isStatic: true,
          chamfer: { radius: paddleHeight / 2 },
          render: { fillStyle: paddleColor },
        });
        Matter.World.add(this.world, this.paddle);

        this.isPaddleExpanded = false;
      }
    },
    displayGameOver(message) {
      this.isGameOver = true;
      this.gameOverMessage = message;

      // Update high score if current score is higher
      if (this.score > this.highScore) {
        this.highScore = this.score; // Update the data property
        localStorage.setItem('highScore', this.score);
      }

      // Stop the physics simulation
      Matter.Runner.stop(this.runner);

      Matter.Events.off(this.engine, 'afterUpdate', this.handleAfterUpdate);


      // Remove event listeners
      this.$refs.gameCanvas.removeEventListener('mousemove', this.movePaddle);
      this.$refs.gameCanvas.removeEventListener('touchmove', this.movePaddle);
      Matter.Events.off(this.engine, 'collisionStart', this.handleCollisions);
      Matter.Events.off(this.engine, 'afterUpdate');
      Matter.Events.off(this.render, 'afterRender', this.renderPowerUpEmojis);
    },

    startOver() {
      // Restart the game
      this.resetGame();
    },
    resetGame() {
      // Remove event listeners
      this.$refs.gameCanvas.removeEventListener('mousemove', this.movePaddle);
      this.$refs.gameCanvas.removeEventListener('touchmove', this.movePaddle);
      Matter.Events.off(this.engine, 'collisionStart', this.handleCollisions);
      Matter.Events.off(this.engine, 'afterUpdate');
      Matter.Events.off(this.render, 'afterRender', this.renderPowerUpEmojis);

      // Stop the runner
      Matter.Runner.stop(this.runner);

      // Clear the world and engine
      Matter.World.clear(this.world);
      Matter.Engine.clear(this.engine);

      // Stop the renderer but keep the canvas
      Matter.Render.stop(this.render);
      this.render.textures = {};

      // Reset variables
      this.ball = null;
      this.paddle = null;
      this.bricks = [];
      this.isGameOver = false;
      this.score = 0;
      this.lives = 3;
      this.currentBallSpeed = this.initialBallSpeed; // Reset ball speed
      this.isPaused = false; // Reset pause state
      this.bricksDestroyed = 0; // Reset destroyed bricks count
      this.brickRows = 5; // Reset initial brick rows
      this.activePowerUps = []; // Reset active power-ups
      this.pointMultiplier = 1; // Reset point multiplier
      this.isPaddleExpanded = false; // Reset paddle expansion state

      // Re-setup walls and game objects
      this.setupWalls();
      this.setupGameObjects();

      // Re-add event listeners
      const canvas = this.$refs.gameCanvas;
      canvas.addEventListener('mousemove', this.movePaddle);
      canvas.addEventListener('touchmove', this.movePaddle);
      Matter.Events.on(this.engine, 'collisionStart', this.handleCollisions);

      // Re-attach the afterUpdate event listener
      this.renderLoop();

      // Restart the runner
      Matter.Runner.run(this.runner, this.engine);

      // Restart the renderer
      Matter.Render.run(this.render);

      // Re-setup custom rendering events
      this.setupRenderEvents();
    },
    resetBall() {
      // Reset the ball position and velocity
      const paddleY = this.paddle.position.y;
      const paddleHeight = this.paddle.bounds.max.y - this.paddle.bounds.min.y;
      const ballX = this.paddle.position.x;
      const ballY = paddleY - paddleHeight / 2 - this.ball.circleRadius * 2; // Corrected calculation

      // Remove the old ball
      Matter.World.remove(this.world, this.ball);

      // Get CSS variables
      const ballColor = this.getCSSVariable('--cc_b') || '#e74c3c';

      // Reset ball speed
      this.currentBallSpeed = this.initialBallSpeed;

      // Create a new ball
      const ballRadius = this.ball.circleRadius;
      this.ball = Matter.Bodies.circle(ballX, ballY, ballRadius, {
        restitution: 1,
        friction: 0,
        frictionAir: 0,
        slop: 0,
        inertia: Infinity,
        render: { fillStyle: ballColor },
      });
      Matter.World.add(this.world, this.ball);

      // Set initial velocity
      Matter.Body.setVelocity(this.ball, { x: this.initialBallSpeed * 0.7, y: -this.initialBallSpeed * 0.7 });
    },

    setupWalls() {
      const width = this.gameWidth;
      const height = this.gameHeight;

      // Create walls
      const wallThickness = 50;
      this.walls = [
        // Left wall
        Matter.Bodies.rectangle(-wallThickness / 2, height / 2, wallThickness, height * 2, {
          isStatic: true,
          render: { visible: false },
        }),
        // Right wall
        Matter.Bodies.rectangle(width + wallThickness / 2, height / 2, wallThickness, height * 2, {
          isStatic: true,
          render: { visible: false },
        }),
        // Top wall
        Matter.Bodies.rectangle(width / 2, -wallThickness / 2, width, wallThickness, {
          isStatic: true,
          render: { visible: false },
        }),
      ];
      Matter.World.add(this.world, this.walls);
    },

    moveBricksDown() {
      const height = this.gameHeight;
      const brickHeight = height / 20; // Consistent with brick height in createBrickRows
      this.bricks.forEach((brick) => {
        Matter.Body.translate(brick, { x: 0, y: brickHeight });
      });
    },
    addNewBrickRow() {
      const width = this.gameWidth;
      const height = this.gameHeight;

      const brickHeight = height / 20;
      const brickWidth = brickHeight;
      const brickCols = Math.floor(width / brickWidth);
      const horizontalMargin = (width - (brickCols * brickWidth)) / 2;

      // Randomly select a power-up type
      const powerUpTypes = ['heart', 'wind', 'expand', 'double'];
      const powerUpType = powerUpTypes[Math.floor(Math.random() * powerUpTypes.length)];
      // Select a random column for the power-up in this row
      const powerUpCol = Math.floor(Math.random() * brickCols);

      for (let col = 0; col < brickCols; col++) {
        const brickX = horizontalMargin + col * brickWidth + brickWidth / 2;
        const brickY = brickHeight / 2 + brickHeight; // Adjust as needed

        const isPowerUp = col === powerUpCol;

        const brickOptions = {
          isStatic: true,
          render: {
            fillStyle: isPowerUp ? '#A9A9A9' : this.getRandomColor(),
            visible: !isPowerUp,
          },
        };

        const brick = Matter.Bodies.rectangle(
          brickX,
          brickY,
          brickWidth - 2,
          brickHeight - 2,
          brickOptions
        );

        if (isPowerUp) {
          brick.isPowerUp = true;
          brick.powerUpType = powerUpType;
          brick.animationOffset = Math.random() * 1000;
        }

        this.bricks.push(brick);
        Matter.World.add(this.world, brick);
      }
    },

    setupRenderEvents() {
      this.renderPowerUpEmojis = () => {
        const context = this.render.context;

        this.bricks.forEach((brick) => {
          if (brick.isPowerUp) {
            const position = brick.position;
            const width = brick.bounds.max.x - brick.bounds.min.x;
            const height = brick.bounds.max.y - brick.bounds.min.y;

            // Update each brick's animation offset
            brick.animationOffset += 2; // Adjust speed as needed

            // Create gradient with brick's own animation offset
            const gradient = context.createLinearGradient(
              position.x - width / 2 - (brick.animationOffset % (width * 2)),
              0,
              position.x + width / 2 - (brick.animationOffset % (width * 2)),
              0
            );
            gradient.addColorStop(0, '#6a11cb');
            gradient.addColorStop(0.5, '#2575fc');
            gradient.addColorStop(1, '#6a11cb');

            // Draw rectangle with gradient
            context.fillStyle = gradient;
            context.fillRect(position.x - width / 2, position.y - height / 2, width, height);

            // Draw emoji
            context.font = '20px Arial';
            context.textAlign = 'center';
            context.textBaseline = 'middle';
            let emoji = '';
            switch (brick.powerUpType) {
              case 'heart':
                emoji = '❤️';
                break;
              case 'wind':
                emoji = '🐢';
                break;
              case 'expand':
                emoji = '🏓';
                break;
              case 'double':
                emoji = '✨';
                break;
            }
            context.fillStyle = '#ffffff'; // Ensure the emoji is visible
            context.fillText(emoji, position.x, position.y);
          }
        });
      };

      Matter.Events.on(this.render, 'afterRender', this.renderPowerUpEmojis);
    },

    getCSSVariable(variableName) {
      return getComputedStyle(document.documentElement).getPropertyValue(variableName).trim();
    },
    getRandomColor() {
      // Define green shades
      const colors = ['#a8e6cf', '#dcedc1', '#aed581', '#81c784'];

      // Select a random color
      const randomIndex = Math.floor(Math.random() * colors.length);
      return colors[randomIndex];
    },
    togglePause() {
      this.isPaused = !this.isPaused;
      if (this.isPaused) {
        Matter.Runner.stop(this.runner);
      } else {
        Matter.Runner.run(this.runner, this.engine);
      }
    },
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.calculateGameContainerSize);

    // Clean up Matter.js engine
    if (this.render) {
      Matter.Render.stop(this.render);
      Matter.Runner.stop(this.runner);
      Matter.World.clear(this.world);
      Matter.Engine.clear(this.engine);
      if (this.render.canvas) this.render.canvas.remove();
      this.render.textures = {};
      Matter.Events.off(this.render, 'afterRender', this.renderPowerUpEmojis);
    }

    // Remove event listeners
    if (this.$refs.gameCanvas) {
      this.$refs.gameCanvas.removeEventListener('mousemove', this.movePaddle);
      this.$refs.gameCanvas.removeEventListener('touchmove', this.movePaddle);
    }
    Matter.Events.off(this.engine, 'afterUpdate', this.handleAfterUpdate);
    Matter.Events.off(this.engine, 'collisionStart', this.handleCollisions);
    Matter.Events.off(this.engine, 'afterUpdate');
  },
};
</script>

<style>
.game-container {
  position: relative;
  margin: 0 auto;
  background-color: #2c3e50;
  overflow: hidden;
  border: 1px solid #ccc;
}

canvas {
  width: 100%;
  height: 100%;
}

.game-over-overlay,
.game-start-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(44, 62, 80, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--cc_third);
  font-size: 48px;
  z-index: 10;
}

.game-over-overlay div {
  margin-bottom: 10px;
}

.score-display {
  position: absolute;
  top: 10px;
  left: 10px;
  color: var(--cc_third);
  font-size: 24px;
  z-index: 5;
  display: flex;
  gap: 20px;
}

.lives-display {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  z-index: 5;
}

.lives-display .heart {
  font-size: 24px;
  margin-left: 5px;
  color: red;
}

.speed-display {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: var(--cc_third);
  font-size: 18px;
  z-index: 5;
}

.power-up-display {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: var(--cc_third);
  font-size: 18px;
  z-index: 5;
  display: flex;
  gap: 20px;
}

.power-up-item {
  display: flex;
  align-items: center;
}

.pause-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 5;
  padding: 2px 8px;
  font-size: 14px;
  background-color: var(--cc_second);
  color: var(--cc_third);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .score-display {
    font-size: 16px;
  }
  .lives-display .heart {
    font-size: 16px;
  }
  .speed-display {
    font-size: 14px;
  }
}

</style>
