<template>
  <div class="text-center">
    <h1 class="display-4">Page under Maintenance</h1>
    <p>But here's an advice box using free aviceslip api</p>
          <div class="row justify-content-center ">
            <div class="col-lg-3">
              <div class="card text-white bg-dark border advice">
                <div class="card-body ">
                  <h5 class="card-title">Advice</h5>
                  <p v-if="info">{{ info }}</p>
                  <p v-else style="color: transparent;">Fetching advice...</p>
                  <button @click="newAdvice" type="button" class="btn">New Advice</button>
                </div>
              </div>
          </div>
    </div>
  </div>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
    name:'AboutView',
    data(){
      return{
        info:null,
      }
    },
    methods: {
      newAdvice(){
        axios
        .get('https://api.adviceslip.com/advice')
        .then(response => (this.info = response.data.slip.advice))
      }
    },
    mounted () {
      axios
        .get('https://api.adviceslip.com/advice')
        .then(response => (this.info = response.data.slip.advice))
    }
  };
  </script>

<style>

/* Adjustments for mobile views */
@media (max-width: 768px) {
    .advice{
      width: 100%;
  }
}

</style>