<template>
  <div class="container py-5">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <h1 class="text-center mb-5 ">Get in touch (Doesn't currently work)</h1>
        <p class="text-center mb-4">Hey there, fill out this form with any questions you may or may not have</p>
        <p class="text-center mb-5">I will reach out to you as soon as possible</p>
      </div>
    </div>
    <div class="row justify-content-center ">
      <div class="col-lg-8">
        <form class="p-5 rounded" name="contactForm" @submit.prevent="submitForm($event)" netlify>
          <div class="row g-3">
            <div class="col-md-6 form-group">
              <label for="nameInput" class="form-label">NAME</label>
              <input type="text" class="form-control custom-input" id="nameInput" placeholder="Enter your name">
            </div>
            <div class="col-md-6">
              <label for="emailInput" class="form-label">EMAIL ADDRESS</label>
              <input type="email" class="form-control custom-input" id="emailInput" placeholder="Enter your email">
            </div>
          </div>
          <div class="row g-3 mt-3">
            <div class="col-12">
              <label for="message" class="form-label">MESSAGE</label>
              <textarea class="form-control custom-input" id="message" rows="4" placeholder="Enter your message"></textarea>
            </div>
          </div>
          <div class="text-center mt-4">
            <button type="submit" class="btn w-100">SEND</button>
          </div>
        </form>

        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactView',
  methods: {
    submitForm(event) {
      event.preventDefault();

      const myForm = event.target;
      const formData = new FormData(myForm);
      
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      })
      .then(() => console.log("Form successfully submitted"))
      .catch((error) => console.error("Form submission error", error));

      alert('Didnt work, get in touch through my other socials. (links at the bottom of the page)');

    }
  }
}
</script>

<style scoped>
  
  .custom-input {
    background-color: var(--cc_Form);
    color: var(--cc_third);
    border: none; /* removes the default border*/
    border-bottom: 2px solid var(--cc_third);
    margin-bottom: 1px;
  }
  
  .custom-input::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
  
  .custom-input:focus {
    box-shadow: none;
    outline: none;
    border-bottom: 3px solid var(--cc_second);
    margin-bottom: -1px;
    
  }

  

  
</style>