<template>
  <div class="home">
    
    <div class="fullscreen-image-with-greeting">
      <img src="../assets/images/mountain.jpeg" alt="Mountain" class="background-image">
      <div class="greeting text-center">
        <h1 class="display-4">{{ greetingMessage }}</h1>
        <p class="lead">Things are still being built here so you may face a buggy and half-ready website</p>
      </div>
    </div>


    <div class="container py-5">
      

      <!-- About Me Section -->
      <div class="row about-section align-items-center">
        <!-- Image on the left -->
        <div class="col-md-6 order-md-1">
          <img src="../assets/images/portrait_Jonathan.jpeg" alt="Portrait" class="img-fluid rounded shadow">
        </div>
        <div class="col-md-6 order-md-2">
          <h2>Short about me.</h2>
          <p>Name: Jonathan Back</p>
          <p>Age: {{ dynamicAge }} years old</p>
          <p>Living: Currently in Jakobstad, Finland</p>
          <p>Degree: Bachelor's in Information Technology from Novia University of Applied Sciences</p>
          <p>Interests: All things tech, FPV drones, Fishing, Nature overall🌱, Cycling, Gym</p>
          <p>Work: Currently as System Developer at <a href="https://www.b2-impact.fi/en/" target="_blank">B2 Impact</a></p>
        </div>
      </div>
      

      <!-- Skills Section -->
      <div class="row skills-section">
        <div class="col-12">
          <section>
            <h2>My Skills</h2>
            <h5>I'm a Junior Developer, I learn as I go, same as everyone else 📚 This is just showing what I'm comfortable with using</h5>
            <small>(Because how do you know 60% of Javascript. What's the remaining 40%?)</small>
            <div class="row">
              <div class="col-12" v-for="skill in skills" :key="skill.name">
                <span class="skill-name">{{ skill.name }}</span>
                <div class="progress my-2">
                  <div class="progress-bar"  :style="{ width: skill.level + '%' }">{{ skill.level }}%</div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="col-12">
        </div>
        <div class="row skills-languages-section d-flex">
          <!-- Languages Section -->
          <div class="col-md-4 languages-section">
            <h3>Languages</h3>
            <div v-for="language in languages" :key="language.name" class="language-skill">
              <span>{{ language.name }}</span>
              <StarRating :rating="language.level" />
            </div>
          </div>

          <!-- Other Skills Section -->
          <div class="col-md-8 other-skills-section">
            <h3>Other Skills</h3>
            <div v-for="skill in otherSkills" :key="skill.name" class="other-skill">
              <span class="skill-name">{{ skill.name }}</span>
              <div class="progress my-2">
                <div class="progress-bar" :style="{ width: skill.level + '%' }">{{ skill.level }}%</div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- Technologies Section -->
      <div class="row technologies-section">
        <div class="col-12">
          <section>
            <h2>Technologies I Use</h2>
            <div class="tech-logos d-flex flex-wrap justify-content-center">
              <img src="../assets/icons/azure.png" alt="Azure" >
              <img src="../assets/icons/vue.png" alt="Vue">
              <img src="../assets/icons/bootstrap.png" alt="Bootstrap">
              <img src="../assets/icons/react.png" alt="React">
              <img src="../assets/icons/nodejs.png" alt="Nodejs">
              <img src="../assets/icons/dotnet.png" alt=".Net">
              <img src="../assets/icons/capacitor.png" alt="Capacitorjs">
            </div>
          </section>
        </div>
      </div>


      <!--Git History-->
      <div class="row git-section py-5">
        <div class="col-12">
          <section>
            <h2>Git History</h2>
            I write code*
            <div class="d-flex justify-content-center">
              <img src="../assets/images/git.png" alt="Git History" >
            </div>
            <small>* this is an approximation and may actually only be me trying to center the image above</small>
          </section>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import StarRating from '../components/StarRating.vue';

import axios from 'axios';
export default {
  name: 'HomeView',
  components: {
    StarRating
  },
  data() {
    return {
      greetingMessage: '',
      time:null,
      dynamicAge:null,
      skills: [
        { name: 'JavaScript', level: 60 },
        { name: 'CSS', level: 40 },
        { name: 'SQL', level: 60 },
        { name: 'C#', level: 50 },
      ],
      languages: [
        { name: 'English', level: 4 },
        { name: 'Swedish', level: 5 },
        { name: 'Finnish', level: 3 },
      ],
      otherSkills: [
        { name: 'Giphy', level: 80 },
        { name: 'Merging Pull Requests', level: 90 },
        { name: 'Being Tall', level: 100 },
      ],
    }
  },
  created() {
    this.setGreeting();
  },
  methods: {
    newAdvice(){
      axios
      .get('https://api.adviceslip.com/advice')
      .then(response => (this.info = response.data.slip.advice))
    },
    setGreeting() {
      this.time=new Date()
      const hour = new Date().getHours();
      if (hour < 12) {
        this.greetingMessage = 'Good Morning☀️';
      } else if (hour < 18) {
        this.greetingMessage = 'Good Afternoon☀️';
      } else {
        this.greetingMessage = 'Good Evening🌙';
      }
    },
    calculateAge() {
      const birthDate = new Date('1999-04-13');
      const today = new Date();
      const age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        this.dynamicAge = age - 1;
      } else {
        this.dynamicAge = age;
      }
    }
  },
  mounted () {
    axios
      .get('https://api.adviceslip.com/advice')
      .then(response => (this.info = response.data.slip.advice)),
    
      this.calculateAge();
  }
}
</script>

<style>
.home {
  display: block;
  
}

.fullscreen-image-with-greeting {
  position: relative;
  height: 100vh; /* Full height of the viewport */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.fullscreen-image-with-greeting::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(var(--cc_main), transparent);
  z-index: 2; /* Ensures the fade effect appears over the image but under the text */
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: 1;
}

.greeting {
  position: relative;
  z-index: 2; /* Ensures the greeting text appears above the image */
  color: var(--cc_third);
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6); /* Adds shadow to text */

}

.container {
  z-index: 3;
  position: relative;
}

/* Adjustments for mobile views */
@media (max-width: 768px) {
  .row > [class^="col-"] {
    display: flex;
    flex-direction: column;
    align-items: center;
  }


  .about-section {
    text-align: center;
  }
  /* Adjust image size on smaller screens if necessary */
  .about-section img {
    margin-top: 20px; /* Adds some space above the image when it moves below the text */
  }

  .about-section h2 {
    font-size: 1.75rem; /* Slightly smaller on mobile devices */
  }

  .about-section p, .about-section a {
    font-size: 1.1rem; /* Adjust font size for better readability on smaller screens */
  }


  /* Enhance progress bars for mobile */
  .skills-section .progress {
    min-width: 80vw; /*Widens the progress bar for mobile */
  }

  .skills-section{
    text-align: center;
  }


}




/* About Me Section Styles */
.about-section img {
  max-width: 100%;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.about-section h2 {
  font-size: 2rem; /* Larger font size for the section heading */
  margin-bottom: 1rem; /* Adds some space below the heading */
}

.about-section p {
  font-size: 1.2rem; /* Slightly larger font size for paragraphs */
  line-height: 1.6; /* Increased line height for readability */
}

.about-section a {
  font-size: 1.2rem; /* Ensures links are also larger and more readable */
}

.about-section{
  padding: 5rem 0;
}




/* Skills Section Styles */
.skills-section .skill-name {
  display: block;
  font-weight: bold;
}

.skills-section .progress {
  border-radius: 20px; /* Makes the borders rounder */
  height: 20px; /* Makes the progress bar thicker */
  font-weight: bold;
  font-size: medium;
}


.skills-section .progress-bar{
  background-color: var(--cc_second);
  color: var(--cc_Form);
}

.skills-section .col-12 {
    margin-bottom: 5px
  }

.skills-section{
  padding: 5rem 0;
}




/* Technologies Section Styles */
.technologies-section .tech-logos img {
  max-width: 100px; /* Adjust based on your preference */
  margin: 10px;
  transition: transform 0.2s;
}

.technologies-section .tech-logos img:hover {
  transform: scale(1.15);
}

.technologies-section{
  padding: 5rem 0;
  text-align: center;
}


.skills-languages-section {
  display: flex;
  flex-wrap: wrap;
}

.languages-section, .other-skills-section {
  display: flex;
  flex-direction: column;
}

.other-skill, .language-skill {
  flex: 1;
  align-items: center; /* This ensures items in each row are vertically centered */
}

/* Ensures that both sections take full height of their container */
.languages-section, .other-skills-section {
  flex-grow: 1;
}


.git-section img{
  width: 100%
}



</style>
