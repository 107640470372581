<template>
    <div class="star-rating">
      <span v-for="star in 5" :key="star" class="star" v-html="star <= rating ? filledStar : emptyStar"></span>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      rating: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
        filledStar: '&#9733;', // Unicode for filled star
        emptyStar: '&#9734;' // Unicode for empty star
      };
    }
  };
  </script>
  
  <style scoped>
  .star-rating .star {
    color: var(--cc_second);
    margin-right: 4px;
    font-size: 30px;
  }
  </style>
  